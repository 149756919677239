import React from 'react';
import { motion } from 'framer-motion';
import { ShoppingBag, Users, Zap, Gift } from 'lucide-react';
import Header from './Header';

const fadeInUp = {
  initial: { opacity: 0, y: 60 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.6 }
};

const Card = ({ children, className = '' }) => (
  <div className={`bg-white/80 backdrop-blur p-6 rounded-lg shadow-sm hover:shadow-lg transition-shadow duration-300 ${className}`}>
    {children}
  </div>
);

const About = () => {
  return (
    <>
    <Header />
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-50 py-12 px-4">
      <motion.div 
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        className="max-w-7xl mx-auto"
      >
        <div className="text-center mb-16">
          <motion.h1 
            className="text-4xl font-bold text-gray-900 mb-4"
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
          >
            À propos de F@yClick
          </motion.h1>
          <motion.div 
            className="h-1 w-24 bg-indigo-600 mx-auto rounded-full"
            initial={{ width: 0 }}
            animate={{ width: 96 }}
            transition={{ duration: 0.8 }}
          />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <motion.div {...fadeInUp}>
            <Card className="h-full">
              <div className="flex items-center mb-4">
                <ShoppingBag className="w-8 h-8 text-indigo-600 mr-3" />
                <h2 className="text-2xl font-semibold text-gray-800">Présentation</h2>
              </div>
              <p className="text-gray-600 leading-relaxed">
                F@yClick est une application innovante conçue pour simplifier les paiements et la gestion des factures pour les acteurs du secteur informel. Elle permet de dématérialiser les factures et de faciliter les transactions avec un QR code marchand, rendant les paiements accessibles 24/7.
              </p>
            </Card>
          </motion.div>

          <motion.div {...fadeInUp} transition={{ delay: 0.2 }}>
            <Card className="h-full">
              <div className="flex items-center mb-4">
                <Users className="w-8 h-8 text-indigo-600 mr-3" />
                <h2 className="text-2xl font-semibold text-gray-800">Cibles</h2>
              </div>
              <p className="text-gray-600 leading-relaxed">
                F@yClick est spécialement conçue pour les acteurs du secteur informel, notamment les PME/PMI, les commerces, et autres structures marchandes. Elle répond aux besoins de ceux qui cherchent à moderniser leurs pratiques de gestion et de paiement.
              </p>
            </Card>
          </motion.div>

          <motion.div {...fadeInUp} transition={{ delay: 0.3 }}>
            <Card className="h-full">
              <div className="flex items-center mb-4">
                <Zap className="w-8 h-8 text-indigo-600 mr-3" />
                <h2 className="text-2xl font-semibold text-gray-800">Comment ça marche</h2>
              </div>
              <ul className="space-y-3">
                {[
                  'Créez un compte Orange Money pour recevoir des paiements à tout moment.',
                  'Vos factures sont dématérialisées avec QR code marchand.',
                  'Chargez vos listes de produits ou services dans la plat-forme.',
                  'Générez des factures automatiquement pour chaque transaction.'
                ].map((item, index) => (
                  <li key={index} className="flex items-center text-gray-600">
                    <span className="w-2 h-2 bg-indigo-600 rounded-full mr-2 flex-shrink-0" />
                    {item}
                  </li>
                ))}
              </ul>
            </Card>
          </motion.div>

          <motion.div {...fadeInUp} transition={{ delay: 0.4 }}>
            <Card className="h-full">
              <div className="flex items-center mb-4">
                <Gift className="w-8 h-8 text-indigo-600 mr-3" />
                <h2 className="text-2xl font-semibold text-gray-800">Avantages</h2>
              </div>
              <div className="overflow-y-auto max-h-[300px] pr-2">
                <div className="space-y-6">
                  <div>
                    <h3 className="text-lg font-semibold text-gray-700 mb-2">
                      Pour les PME/PMI et Commerces :
                    </h3>
                    <ul className="space-y-2">
                      {[
                        'Factures et reçus 100% numériques',
                        'Traçabilité complète des paiements',
                        'Enregistrement automatique des transferts'
                      ].map((item, index) => (
                        <li key={index} className="flex items-center text-gray-600">
                          <span className="w-2 h-2 bg-indigo-600 rounded-full mr-2 flex-shrink-0" />
                          {item}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div>
                    <h3 className="text-lg font-semibold text-gray-700 mb-2">
                      Pour vos clients :
                    </h3>
                    <ul className="space-y-2">
                      {[
                        'Paiements à distance',
                        'Élimination des transferts hasardeux',
                        'Flexibilité des paiements 24/7'
                      ].map((item, index) => (
                        <li key={index} className="flex items-center text-gray-600">
                          <span className="w-2 h-2 bg-indigo-600 rounded-full mr-2 flex-shrink-0" />
                          {item}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </Card>
          </motion.div>
        </div>
      </motion.div>
    </div>
    </>
  );
};

export default About;