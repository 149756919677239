import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './styles/Home.css';
import Footer from './Footer';

function Home() {
  const navigate = useNavigate();

  const images = [
    `${process.env.PUBLIC_URL}/images/bannieres/p1.webp`,
    `${process.env.PUBLIC_URL}/images/bannieres/p2.webp`,
    `${process.env.PUBLIC_URL}/images/bannieres/p3.webp`,
    `${process.env.PUBLIC_URL}/images/bannieres/p5.webp`,
    `${process.env.PUBLIC_URL}/images/bannieres/p4.webp`,
   
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [fade, setFade] = useState(true);


  useEffect(() => {
    const interval = setInterval(() => {
      setFade(false);
      setTimeout(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        setFade(true);
      }, 1000);
    }, 6000);

    return () => clearInterval(interval);
  }, [images.length]);

  const handleLoginClick = () => {
    navigate('/login');
  };

  return (
    <div className={`home-background ${fade ? 'fade-in' : 'fade-out'}`} style={{ backgroundImage: `url(${images[currentImageIndex]})` }}>
      <header className="home-header">
        <h1>F@yClic</h1>
        <p>Sans papiers, sans déplacements, sans stress, payer en toute tranquillité</p>
        <button className="btnStructure" onClick={() => navigate('/add_edit_structure')}>Créer ma structure</button>
      </header>

      {/* Container pour les boutons centraux */}
      <div className="btn-container">
        <button className="btnConnexion" onClick={handleLoginClick}>Entrer</button>
        <button className="btnConnexion" onClick={() => navigate('/jepay')}>Payer une facture</button>
      </div>

      <p style={{ color: 'transparent' }}>hello</p>
      <Footer />
    </div>
  );
}

export default Home;
