import React, { useState } from 'react';
import '../styles/jepay.css';
import { getReponseApi } from '../apis/api_backend';
import Header from '../Header';
import PopupPayement from './PopupPayement';
import TicketRecu from './TicketRecu';
import { Search, Phone, FileText, Filter, CreditCard, Download } from 'lucide-react';

const Jepay = () => {
  const [factures, setFactures] = useState([]);
  const [filteredFactures, setFilteredFactures] = useState([]);
  const [numtelclient, setNumTelClient] = useState('');
  const [numfacture, setNumFacture] = useState('');
  const [searchBy, setSearchBy] = useState('tel');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showSelect, setShowSelect] = useState(false);
  const [etatFilter, setEtatFilter] = useState('');
  const [popupType, setPopupType] = useState(null);
  const [selectedFacture, setSelectedFacture] = useState(null);

  const formatMontant = (montant) => {
    return Math.floor(montant).toLocaleString('fr-FR', {
      useGrouping: true,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }) + ' FCFA';
  };

  const handleSearch = async () => {
    setLoading(true);
    setError(null);
    setShowSelect(false);

    const body = {
      numtelclient: searchBy === 'tel' ? numtelclient : '',
      numfacture: searchBy === 'facture' ? numfacture : ''
    };

    try {
      const response = await getReponseApi('/factures/trouverfacture', 'POST', body);
      if (response && Array.isArray(response)) {
        setFactures(response);
        setFilteredFactures(response);
        setShowSelect(true);
      } else {
        setFactures([]);
        setFilteredFactures([]);
        setShowSelect(false);
        setError('Aucune facture trouvée');
      }
    } catch (error) {
      setError('Erreur lors de la récupération des factures. Veuillez réessayer.');
    } finally {
      setLoading(false);
    }
  };

  const handlePayClick = (facture) => {
    setSelectedFacture(facture);
    setPopupType('paiement');
  };

  const handleRecuClick = (facture) => {
    setSelectedFacture(facture);
    setPopupType('recu');
  };

  const closePopup = () => {
    setSelectedFacture(null);
    setPopupType(null);
  };

  const handleInputChange = (setter) => (e) => {
    setter(e.target.value);
    setShowSelect(false);
    setEtatFilter('');
  };

  const handleEtatChange = (e) => {
    const selectedEtat = e.target.value;
    setEtatFilter(selectedEtat);
    setFilteredFactures(
      selectedEtat === 'impayee' 
        ? factures.filter(facture => facture.id_etat === 1)
        : selectedEtat === 'payee'
          ? factures.filter(facture => facture.id_etat !== 1)
          : factures
    );
  };

  return (
    <div className="page-container">
      <Header />
      
      <div className="container">
        <div className="welcome-section">
          <h1>Gérez vos paiements en toute simplicité</h1>
          <p>Retrouvez et payez vos factures en quelques clics</p>
        </div>

        <div className="search-card">
          <div className="search-options">
            <label className={`search-option ${searchBy === 'tel' ? 'active' : ''}`}>
              <input 
                type="radio" 
                name="searchBy" 
                value="tel"
                checked={searchBy === 'tel'}
                onChange={() => setSearchBy('tel')}
              />
              <Phone size={20} />
              <span>Recherche par N°Tél</span>
            </label>
            <label className={`search-option ${searchBy === 'facture' ? 'active' : ''}`}>
              <input 
                type="radio" 
                name="searchBy" 
                value="facture"
                checked={searchBy === 'facture'}
                onChange={() => setSearchBy('facture')}
              />
              <FileText size={20} />
              <span>Recherche par N° Facture</span>
            </label>
          </div>

          <div className="search-input-container">
            {searchBy === 'tel' && (
              <div className="input-wrapper">
                <Phone size={20} className="input-icon" />
                <input 
                  type="text" 
                  placeholder="Entrez votre numéro de téléphone" 
                  value={numtelclient} 
                  onChange={handleInputChange(setNumTelClient)} 
                />
              </div>
            )}
            {searchBy === 'facture' && (
              <div className="input-wrapper">
                <FileText size={20} className="input-icon" />
                <input 
                  type="text" 
                  placeholder="Entrez le numéro de facture" 
                  value={numfacture} 
                  onChange={handleInputChange(setNumFacture)} 
                />
              </div>
            )}
            <button 
              className="search-button"
              onClick={handleSearch} 
              disabled={loading}
            >
              {loading ? (
                <div className="loader">Recherche en cours...</div>
              ) : (
                <>
                  <Search size={20} />
                  <span>Rechercher</span>
                </>
              )}
            </button>
          </div>

          {error && (
            <div className="error-message">
              <span>⚠️</span> {error}
            </div>
          )}
        </div>

        {factures.length > 0 && showSelect && (
          <div className="filter-section">
            <div className="filter-wrapper">
              <Filter size={20} />
              <select value={etatFilter} onChange={handleEtatChange}>
                <option value="">Tous les états</option>
                <option value="impayee">Factures impayées</option>
                <option value="payee">Factures payées</option>
              </select>
            </div>
          </div>
        )}

{filteredFactures.length > 0 && (
  <div className="table-container">
    <table>
      <thead>
        <tr>
          <th>Période</th>
          <th>Description</th>
          <th>Montant</th>
          <th>État</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
          {filteredFactures.map((facture) => (
            <tr key={facture.id_facture}>
              <td>{facture.nmois + '-' + facture.nannee}</td>
              <td>{facture.nom_classe + ' : ' + facture.nom_client}</td>
              <td className="amount">{formatMontant(facture.montant)}</td>
              <td>
                <span className={`status-badge ${facture.id_etat === 1 ? 'unpaid' : 'paid'}`}>
                  {facture.id_etat === 1 ? 'À payer' : 'Payée'}
                </span>
              </td>
              <td>
                {facture.id_etat === 1 ? (
                  <button 
                    className="action-button pay"
                    onClick={() => handlePayClick(facture)}
                    title="Payer"
                  >
                    <CreditCard size={16} />
                    <span className="button-text">Payer</span>
                  </button>
                ) : (
                  <button 
                    className="action-button receipt"
                    onClick={() => handleRecuClick(facture)}
                    title="Reçu"
                  >
                    <Download size={16} />
                    <span className="button-text">Reçu</span>
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )}

        {selectedFacture && popupType === 'paiement' && (
          <PopupPayement facture={selectedFacture} onClose={closePopup} />
        )}
        {selectedFacture && popupType === 'recu' && (
          <TicketRecu facture={selectedFacture} onClose={closePopup} />
        )}

        <div className="secure-payment-info">
          <div className="security-badge">
            <span className="security-icon">🔒</span>
            <p>Paiements sécurisés via Orange Money</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Jepay;