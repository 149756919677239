import React from 'react';
import '../styles/ticketRecu.css';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faUser, 
  faPhone, 
  faBuilding, 
  faFileInvoice, 
  faFileAlt, 
  faGraduationCap, 
  faCalendarAlt, 
  faMoneyBillWave, 
  faPrint, 
  faTimes,
  faCheckCircle,
  faClock
} from '@fortawesome/free-solid-svg-icons';

const TicketRecu = ({ facture, onClose }) => {
  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('fr-FR', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const handlePrint = () => {
    // Ajoute une classe temporaire pour l'animation de prévisualisation
    document.querySelector('.popup-content').classList.add('print-preview');
    
    // Petite temporisation pour laisser l'animation se jouer
    setTimeout(() => {
      window.print();
      
      // Retire la classe après l'impression
      setTimeout(() => {
        document.querySelector('.popup-content').classList.remove('print-preview');
      }, 300);
    }, 100);
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const jour = String(date.getDate()).padStart(2, '0');
    const mois = date.toLocaleString('fr-FR', { month: 'short' }).toUpperCase();
    const annee = date.getFullYear();
    return `${jour}/${mois}/${annee}`;
  };

  return (
    <motion.div 
      className="popup-container"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <motion.div 
        className="popup-content"
        initial={{ scale: 0.9, y: 20 }}
        animate={{ scale: 1, y: 0 }}
        exit={{ scale: 0.9, y: 20 }}
      >
        <button className="popup-close" onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </button>

        {/* En-tête avec logo */}
        <div className="ticket-header">
          <div className="logo-wrapper">
            <img 
              src={facture.logo} 
              alt="Logo"
              className="structure-logo"
            />
          </div>
          <div className="receipt-number">
            <FontAwesomeIcon icon={faFileInvoice} className="icon-receipt" />
            <span>{facture.numrecu}</span>
          </div>
          <div className="timestamp">
            <FontAwesomeIcon icon={faClock} className="timestamp-icon" />
            <span>Payée le : {formatTimestamp(facture.tms_update)}</span>
          </div>
        </div>

        {/* Informations Client */}
        <div className="ticket-section">
          <h3 className="section-title">
            <FontAwesomeIcon icon={faUser} className="section-icon" />
            Informations Client
          </h3>
          <div className="info-grid">
            <div className="info-row">
              <span className="info-label">
                <FontAwesomeIcon icon={faUser} className="info-icon" />
                Nom
              </span>
              <span className="info-value">{facture.nom_client}</span>
            </div>
            <div className="info-row">
              <span className="info-label">
                <FontAwesomeIcon icon={faPhone} className="info-icon" />
                Téléphone
              </span>
              <span className="info-value">{facture.tel_client}</span>
            </div>
          </div>
        </div>

        {/* Détails Paiement */}
        <div className="ticket-section">
          <h3 className="section-title">
            <FontAwesomeIcon icon={faMoneyBillWave} className="section-icon" />
            Détails Paiement
          </h3>
          <div className="info-grid">
            <div className="info-row">
              <span className="info-label">
                <FontAwesomeIcon icon={faBuilding} className="info-icon" />
                Structure
              </span>
              <span className="info-value">{facture.nom_structure}</span>
            </div>
            <div className="info-row">
              <span className="info-label">
                <FontAwesomeIcon icon={faFileInvoice} className="info-icon" />
                N° Facture
              </span>
              <span className="info-value">{facture.num_facture}</span>
            </div>
            <div className="info-row">
              <span className="info-label">
                <FontAwesomeIcon icon={faFileAlt} className="info-icon" />
                Description
              </span>
              <span className="info-value">{facture.description}</span>
            </div>
            <div className="info-row">
              <span className="info-label">
                <FontAwesomeIcon icon={faGraduationCap} className="info-icon" />
                Classe
              </span>
              <span className="info-value">{facture.nom_classe}</span>
            </div>
            <div className="info-row">
              <span className="info-label">
                <FontAwesomeIcon icon={faCalendarAlt} className="info-icon" />
                Date
              </span>
              <span className="info-value">{formatDate(facture.date_facture)}</span>
            </div>
          </div>
        </div>

        {/* Montant et Status */}
        <div className="amount-section">
          <div className="amount-row">
            <span className="amount-label">
              <FontAwesomeIcon icon={faMoneyBillWave} className="amount-icon" />
              Montant Total
            </span>
            <span className="amount-value">{facture.montant} F</span>
          </div>
          <div className="status-badge">
            <FontAwesomeIcon icon={faCheckCircle} className="status-icon" />
            {facture.libelle_etat}
          </div>
        </div>

        {/* Bouton d'impression */}
        <button className="print-button" onClick={handlePrint}>
          <FontAwesomeIcon icon={faPrint} className="print-icon" />
          Imprimer le reçu
        </button>
      </motion.div>
    </motion.div>
  );
};

export default TicketRecu;