import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { PayEcoleContext } from '../contexts/PayEcoleContext';
import { Menu, X } from 'lucide-react';
import './styles/Navbar.css';

const DynamicNavbar = () => {
  const { user, logout } = useContext(PayEcoleContext);
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const renderNavLinks = () => {
    if (!user) return null;
    
    const links = {
      SCOLAIRE: [
        { to: "/unestructure", label: "Ma structure" },
        { to: "/paiements", label: "Mes Factures" },
      //  { to: "/utilisateurs", label: "Utilisateurs" },
        { to: "/compte", label: "Mon compte" }
      ],
      COMMERCE: [
        { to: "/unestructure", label: "Ma structure" },
        { to: "/paiements", label: "Mes Factures" },
        { to: "/compte", label: "Mon compte" }
      ],
      CLIENT: [
        { to: "/homeclient", label: "Dashboard" },
        { to: "/transaction", label: "Transactions" },
        { to: "/compte", label: "Mon compte" }
      ],
      DEFAULT: [
        { to: "/systeme", label: "Dashboard" },
        { to: "/structures", label: "Structures" },
        { to: "/Transactions", label: "Consommations" },
        { to: "/utilisateurs", label: "Utilisateurs" },
        { to: "/compte", label: "Mon compte" }
      ]
    };

    const userLinks = links[user.profil] || links.DEFAULT;

    return userLinks.map((link) => (
      <Link
        key={link.to}
        to={link.to}
        className="nav-link"
      >
        {link.label}
      </Link>
    ));
  };

  return (
    <nav className="navbar-main">
      <div className="navbar-container">
        {/* Navigation desktop */}
        <div className="nav-links-desktop">
          {renderNavLinks()}
          <button onClick={handleLogout} className="nav-link logout-btn">
            Déconnexion
          </button>
        </div>

        {/* Bouton hamburger pour mobile */}
        <button
          className="hamburger-btn"
          onClick={() => setIsOpen(!isOpen)}
          aria-label="Menu"
        >
          {isOpen ? (
            <X className="h-6 w-6" />
          ) : (
            <Menu className="h-6 w-6" />
          )}
        </button>

        {/* Menu mobile */}
        <div className={`mobile-menu ${isOpen ? 'show' : ''}`}>
          {renderNavLinks()}
          <button onClick={handleLogout} className="nav-link logout-btn">
            Déconnexion
          </button>
        </div>

        {/* Message de bienvenue */}
        {user && (
          <div className="welcome-message">
            Bienvenue {user.prenom_agent} {user.nom_agent}
          </div>
        )}
      </div>
    </nav>
  );
};

export default DynamicNavbar;