import React, { useState } from 'react';
import '../styles/PopupPayement.css';
import { getReponseApi } from '../apis/api_backend';
import ProcessingModal from './ProcessingModal';

// Tableaux de frais
const factures = [
  1000, 3500, 7800, 8500, 11000, 15000,
  17000, 18000, 20000, 25000, 35000,
  40000, 48500, 50000, 60000, 100000
];

const frais = [
  250, 250, 420, 450, 510, 560,
  620, 625, 630, 720, 980,
  1070, 1250, 1300, 1500, 2300
];

// Fonction pour calculer les frais interpolés
const getFraisInterpolated = (factureMontant) => {
  if (factureMontant <= factures[0]) return frais[0];
  if (factureMontant >= factures[factures.length - 1]) return frais[frais.length - 1];

  let lowerIndex = 0;
  let upperIndex = 0;
  
  for (let i = 0; i < factures.length - 1; i++) {
    if (factureMontant >= factures[i] && factureMontant <= factures[i + 1]) {
      lowerIndex = i;
      upperIndex = i + 1;
      break;
    }
  }

  const x0 = factures[lowerIndex];
  const y0 = frais[lowerIndex];
  const x1 = factures[upperIndex];
  const y1 = frais[upperIndex];

  return y0 + ((factureMontant - x0) * (y1 - y0)) / (x1 - x0);
};

const PopupPayement = ({ facture, onClose, onPaymentComplete }) => {
  const [telephone, setTelephone] = useState(facture.tel_client || '');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [showProcessingModal, setShowProcessingModal] = useState(false);
  
  // Calculer les montants
  const montant = parseFloat(facture.montant);
  const serviceFrais = getFraisInterpolated(montant);
  const netTotal = (serviceFrais + montant).toFixed(2);

  const handleTelephoneChange = (e) => {
    const value = e.target.value;
    if (/^\d{0,9}$/.test(value)) {
      setTelephone(value);
    }
  };

  const formatMontant = (montant) => {
    return Math.floor(montant).toLocaleString('fr-FR', {
      useGrouping: true,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }) + ' FCFA';
  };

  const handlePayClick = () => {
    if (!telephone || telephone.length !== 9) {
      alert('Veuillez saisir un numéro de téléphone valide (9 chiffres).');
      return;
    }
    setShowConfirmation(true);
  };

  const handleConfirmPayment = async () => {
    setIsProcessing(true);
    try {
      const body = {
        nom_app: 'ICELABOSOFT',
        nom_structure: facture.nom_structure,
        numfacture: facture.num_facture,
        numtelclient: telephone,
        montant: montant,
        frais: serviceFrais,
        avec_infosms: true
      };
      
      const response = await getReponseApi('/factures/newpayement', 'POST', body);
  
      if (response.error) {
        throw new Error(response.error);
      }
      
      // Fermer le modal de confirmation et afficher le modal de progression
      setShowConfirmation(false);
      setShowProcessingModal(true);
  
    } catch (error) {
      console.error('Erreur:', error);
      alert('Une erreur est survenue lors de la création de la demande de paiement.');
      setIsProcessing(false);
    }
  };
  
  const handleProcessingComplete = async () => {
    setShowProcessingModal(false);
    setIsProcessing(false);
    onClose();
    // Déclencher la recherche dans le composant parent
    if (onPaymentComplete) {
      onPaymentComplete();
    }
  };
  const handlePaymentStatusChange = (confirmed) => {
    if (confirmed) {
      // Le paiement a été confirmé, on peut mettre à jour l'interface si nécessaire
      console.log("Paiement confirmé par l'API");
    }
  };

  return (
    <div className="popup-container">
      <div className="popup-contentpay">
        <button className="popup-close" onClick={onClose}>X</button>
        <h2 className="popup-title">N° Facture : {facture.num_facture}</h2>

        <p><strong>Nom de la structure :</strong> <span className="highlighted-field">{facture.nom_structure}</span></p>
        
        <table className="popup-table-horizontal">
          <tbody>
            <tr>
              <td><strong>Description</strong></td>
              <td><strong>Montant</strong></td>
            </tr>
            <tr>
              <td>{facture.nom_classe + ' : ' + facture.nom_client}</td>
              <td>{formatMontant(facture.montant)}</td>
            </tr>
          </tbody>
        </table>

        <hr className="popup-separator" />
        <p><strong>Frais du service :</strong> {formatMontant(serviceFrais.toFixed(2))}</p>
        <hr className="popup-separator" />
        <p><strong>Net à payer : </strong> <strong>{formatMontant(netTotal)} </strong></p>
        <hr className="popup-separator" />

        <label htmlFor="telephone"><strong>Numéro de téléphone :</strong></label>
        <input
          type="text"
          id="telephone"
          value={telephone}
          placeholder="Saisir numéro de téléphone"
          onChange={handleTelephoneChange}
          className="popup-input"
          maxLength="9"
        />
        <p><em>(Entrer un N° téléphone qui possède OM)</em></p>
        <hr className="popup-separator" />

        <button 
          className="popup-buttonpay" 
          onClick={handlePayClick}
          disabled={isProcessing}
        >
          <img 
            src={`${process.env.PUBLIC_URL}/images/om.png`} 
            alt="Payer" 
            className="popup-button-image" 
          />
        </button>
      </div>

      {showConfirmation && (
        <div className="confirmation-overlay">
          <div className="confirmation-dialog">
            <div className="confirmation-header">
              <svg width="40" height="40" viewBox="0 0 24 24" className="confirmation-icon">
                <path 
                  fill="currentColor" 
                  d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"
                />
              </svg>
              <h2>CONFIRMATION DU PAIEMENT</h2>
            </div>

            <div className="confirmation-body">
              <div className="confirmation-text">
                Etes vous sûr(e) de payer la facture 
                <span className="highlighted-field">N°{facture.num_facture}</span> 
                à la structure 
                <span className="highlighted-field">{facture.nom_structure}</span> 
                pour un montant de 
                <span className="highlighted-amount">{montant} F</span> ?
              </div>

              <div className="info-box">
                <div className="info-header">
                  NOTE IMPORTANTE
                </div>
                <p className="info-text">
                  Un SMS sera envoyé sur votre N°tel: 
                  <span className="highlighted-phone">{telephone}</span>
                  <br />
                  Vous devez avoir l'application OM ou l'application Maxit pour payer.
                </p>
              </div>
            </div>

            <div className="confirmation-actions">
              <button
                className="action-button cancel"
                onClick={() => setShowConfirmation(false)}
                disabled={isProcessing}
              >
                Annuler
              </button>
              <button
                className="action-button confirm"
                onClick={handleConfirmPayment}
                disabled={isProcessing}
              >
                {isProcessing ? (
                  <span className="loading">
                    <span className="loading-spinner"></span>
                    Traitement...
                  </span>
                ) : (
                  'Valider'
                )}
              </button>
            </div>
          </div>
        </div>
      )}

<ProcessingModal 
        isOpen={showProcessingModal}
        onComplete={handleProcessingComplete}
        id_facture={facture.id_facture}
        onStatusChange={handlePaymentStatusChange}
      />
    </div>
  );
};

export default PopupPayement;