import React, { useState, useEffect } from 'react';
import { getReponseApi } from '../apis/api_backend';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faCheckCircle, 
  faTimesCircle,
  faSave,
  faTimes
} from '@fortawesome/free-solid-svg-icons';
import ImageUpload from '../Admin/ImageUpload';
import '../styles/AddEditStructure.css';
import PopMessage from '../PopMessage';

const API_BASE_URL = process.env.REACT_APP_API_URL || 'https://backend1payecole-aa57a12e35de.herokuapp.com';
const DEFAULT_LOGO = `${process.env.PUBLIC_URL}/images/default-logo.png`;

function AddEditStructure({ mode, structure: initialStructure, onClose }) {
  
  
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const [typeOptions, setTypeOptions] = useState([]);
  const [logoFile, setLogoFile] = useState(null);

  const [structure, setStructure] = useState(() => ({
    id_type: '',
    code_structure: '',
    nom_structure: '',
    adresse: '',
    mobile_om: '',
    mobile_wave: '',
    numautorisatioon: '',
    nummarchand: '',
    email: '',
    id_localite: '',
    actif: true,
    logo: DEFAULT_LOGO,
    ...initialStructure
  }));

  useEffect(() => {
    const fetchTypes = async () => {
      try {
        const response = await getReponseApi('/typestructures', 'GET');
        if (response) setTypeOptions(response);
      } catch (error) {
        console.error('Erreur lors du chargement des types de structure:', error);
        setPopupMessage('Erreur lors du chargement des types de structure');
        setShowPopup(true);
      }
    };

    fetchTypes();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setStructure(prevState => ({
      ...prevState,
      [name]: name === 'id_type' ? parseInt(value, 10) : value
    }));
  };

  const handleLogoChange = (uploadedUrl, file) => {
    setLogoFile(file);
    setStructure(prevState => ({
      ...prevState,
      logo: uploadedUrl
    }));
  };

  const toggleActive = () => {
    setStructure(prevState => ({
      ...prevState,
      actif: !prevState.actif
    }));
  };

  const validateForm = () => {
    const requiredFields = ['nom_structure', 'adresse', 'id_type', 'mobile_om'];
    const missingFields = requiredFields.filter(field => !structure[field]);

    if (missingFields.length > 0) {
      const fieldNames = {
        nom_structure: 'Nom de la structure',
        adresse: 'Adresse',
        id_type: 'Type de structure',
        mobile_om: 'Mobile Orange Money'
      };
      setPopupMessage(`Veuillez remplir les champs suivants : ${missingFields.map(f => fieldNames[f]).join(', ')}`);
      setShowPopup(true);
      return false;
    }

    if (structure.mobile_om && !/^\d{9}$/.test(structure.mobile_om)) {
      setPopupMessage('Le numéro Orange Money doit contenir 9 chiffres');
      setShowPopup(true);
      return false;
    }

    if (structure.email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(structure.email)) {
      setPopupMessage('Format d\'email invalide');
      setShowPopup(true);
      return false;
    }

    return true;
  };

  const handleSave = async () => {
    if (!validateForm()) return;

    const isNew = !structure.id_structure || structure.id_structure === 0;
    const endpoint = isNew ? '/structures' : `/structures/${structure.id_structure}`;
    const method = isNew ? 'POST' : 'PUT';

    const formData = new FormData();
    
    formData.append('id_type', structure.id_type);
    formData.append('nom_structure', structure.nom_structure);
    formData.append('adresse', structure.adresse);
    formData.append('mobile_om', structure.mobile_om);
    formData.append('mobile_wave', structure.mobile_wave || '');
    formData.append('numautorisatioon', structure.numautorisatioon || '');
    formData.append('nummarchand', structure.nummarchand || '');
    formData.append('email', structure.email || '');
    formData.append('id_localite', structure.id_localite || 1);
    formData.append('actif', structure.actif !== undefined ? structure.actif : true);

    if (logoFile) {
      formData.append('logo', logoFile);
    }

    try {
      const response = await fetch(`${API_BASE_URL}${endpoint}`, {
        method: method,
        headers: {
          'Authorization': localStorage.getItem('token'),
        },
        body: formData
      });

      const data = await response.json();
      console.log('Resultat création structure: ', data);
      
      if (isNew && data) {
        // Envoi du SMS d'alerte
        const message_sms = data.message;
        try {
          const body = {
            client: 'ICELABOSOFT',
            telephone: structure.mobile_om,
            message: message_sms,
            origine: structure.nom_structure
          }
          const response = await getReponseApi('/utilisateurs/send_alert', 'POST', body);
          if (response) {
            console.log('SMS envoyé');
          }
        } catch (smsError) {
          console.error('Erreur lors de l\'envoi du SMS:', smsError);
        }

        setPopupMessage(`${structure.nom_structure} créée avec succès. Vous allez recevoir un SMS avec ce message: ${message_sms}`);
        setShowPopup(true);
        
        // Attendre que l'utilisateur voie le message de succès avant de revenir à la page d'accueil
        setTimeout(() => {
          window.history.back();
        }, 40000);
      } else {
        setPopupMessage(data.message || 'Structure mise à jour avec succès');
        setShowPopup(true);
        if (onClose) onClose();
      }
    } catch (error) {
      console.error('Erreur lors de la sauvegarde:', error);
      setPopupMessage('Une erreur est survenue lors de la sauvegarde');
      setShowPopup(true);
    }
  };

  const handlePopupClose = () => {
    setShowPopup(false);
    if (onClose) {
      onClose();
    }
  };

  return (
    <div className="add-edit-structure-form">
      <h2>
        {(mode !== 'edit' || structure.id_structure === 0) 
          ? 'Ajouter votre Structure' 
          : 'Modifier votre Structure'
        }
      </h2>

      <div className="logo-container">
        <ImageUpload
          defaultImage={structure.logo}
          onImageChange={handleLogoChange}
          className="structure-logo"
          size={150}
          shape="circle"
          withFile={true}
        />
      </div>

      <div className="form-field">
        <label>Type de Structure *</label>
        <select 
          name="id_type" 
          value={structure.id_type} 
          onChange={handleChange} 
          required
        >
          <option value="">Sélectionnez un type</option>
          {typeOptions.map(type => (
            <option key={type.id_type} value={type.id_type}>
              {type.nom_type}
            </option>
          ))}
        </select>
      </div>

      {mode === 'edit' && (
        <div className="form-field">
          <label>Code Structure</label>
          <input 
            type="text" 
            name="code_structure" 
            value={structure.code_structure} 
            disabled 
          />
        </div>
      )}

      <div className="form-field">
        <label>Nom de la Structure *</label>
        <input 
          type="text" 
          name="nom_structure" 
          value={structure.nom_structure} 
          onChange={handleChange} 
          required 
          placeholder="Entrez le nom de la structure"
        />
      </div>

      <div className="form-field">
        <label>Adresse *</label>
        <input 
          type="text" 
          name="adresse" 
          value={structure.adresse} 
          onChange={handleChange} 
          required 
          placeholder="Adresse complète"
        />
      </div>

      <div className="form-field">
        <label>N° Tél. Mobile* [ Il vous permet de recevoir des SMS ]</label>
        <input 
          type="text" 
          name="mobile_om" 
          value={structure.mobile_om} 
          onChange={handleChange} 
          required 
          placeholder="Ex: 77XXXXXXX"
          maxLength="9"
        />
      </div>

      <div className="form-field">
        <label>Mobile 2</label>
        <input 
          type="text" 
          name="mobile_wave" 
          value={structure.mobile_wave} 
          onChange={handleChange} 
          placeholder="Ex: 77XXXXXXX"
          maxLength="9"
        />
      </div>

      <div className="form-field">
        <label>Email</label>
        <input 
          type="email" 
          name="email" 
          value={structure.email} 
          onChange={handleChange} 
          placeholder="email@domaine.com"
        />
      </div>

      {mode === 'edit' && (
        <>
          <div className="form-field">
            <label>Numéro d'Autorisation</label>
            <input 
              type="text" 
              name="numautorisation" 
              value={structure.numautorisatioon} 
              onChange={handleChange} 
              placeholder="Numéro d'autorisation"
            />
          </div>

          <div className="form-field">
            <label>Statut</label>
            <div className="status-toggle" onClick={toggleActive}>
              <FontAwesomeIcon
                icon={structure.actif ? faCheckCircle : faTimesCircle}
                className={`status-icon ${structure.actif ? 'active' : 'inactive'}`}
              />
              <span>{structure.actif ? 'Actif' : 'Inactif'}</span>
            </div>
          </div>
        </>
      )}

      <div className="form-buttons">
        <button 
          className="btn save-btn" 
          onClick={handleSave}
        >
          <FontAwesomeIcon icon={faSave} />
          Enregistrer
        </button>
        <button 
          className="btn cancel-btn" 
          onClick={() => window.history.back()}
        >
          <FontAwesomeIcon icon={faTimes} />
          Annuler
        </button>
      </div>

      {showPopup && (
        <PopMessage
          message={popupMessage}
          onClose={handlePopupClose}
        />
      )}
    </div>
  );
}

export default AddEditStructure;