// frontend/src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ProtectedRoute from './components/ProtectedRoute';
import SessionTimeout from './contexts/SessionTimeout';

import HomePage from './components/Home';
import Login from './components/Login';
import Compte from './components/Admin/MonCompte';
import About from './components/About';
import AddEditStructure from './components/Admin/AddEditStructure';
import Listfactures from  './components/Admin/ListFactures';
import PayFacture from './components/Admin/jepay';
import PopPayement from './components/Admin/PopupPayement';
import PopTicketRecu from './components/Admin/TicketRecu';

import UneStructure from './components/Admin/UneStructure';
import { PayEcoleProvider } from './contexts/PayEcoleContext';
import { AuthProvider } from './contexts/AuthContext';

function App() {
    return (
        <AuthProvider>
        <PayEcoleProvider>
          <Router>
            <SessionTimeout timeout={180000} />
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/login" element={<Login />} />
              <Route path="/about" element={<About />} />
              <Route path="/add_edit_structure" element={<AddEditStructure />} />
              <Route path="/jepay" element={<PayFacture />} />
              <Route path="/recu" element={<PopTicketRecu />} />
              <Route path="/popup" element={<PopPayement />} />

              {/* Routes protégées */}
              <Route element={<ProtectedRoute />}>

                <Route path="/compte" element={<Compte />} />
                <Route path="/unestructure" element={<UneStructure />} />
               
                
                <Route path="/paiements" element={<Listfactures />} />
              </Route>
            </Routes>
          </Router>
        </PayEcoleProvider>
      </AuthProvider>
    );
}

export default App;
