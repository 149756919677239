import React, { useState, useContext, useEffect } from 'react';
import { PayEcoleContext } from '../../contexts/PayEcoleContext';
import Modal from './Modal';
import AddEditStructure from './AddEditStructure';
import ImportTab from './ImportTab';
import DonneesTab from './DonneesTab'; // Nouveau import
import Header from '../Header';
import { getReponseApi } from '../apis/api_backend';

import '../styles/UneStructure.css';

function UneStructure() {
  const { user, structure, setStructure } = useContext(PayEcoleContext);
  const [activeTab, setActiveTab] = useState(0);
  const [showEdit, setShowEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [localStructure, setLocalStructure] = useState(null);

  useEffect(() => {
    const fetchStructure = async () => {
      try {
        if (user && user.id_structure && !structure) {
          setLoading(true);
          console.log(`Appel de l'API pour la structure avec id:`, user.id_structure);
          const response = await getReponseApi(`/structures/${user.id_structure}`, 'GET');

          if (response && Array.isArray(response) && response.length > 0) {
            const structureData = response[0];
            console.log('Structure chargée:', structureData);
            setStructure(structureData);
            setLocalStructure(structureData);
          }
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des informations de la structure:', error);
      } finally {
        setLoading(false);
      }
    };

    if (!structure && user && user.id_structure) {
      console.log('Chargement de la structureActuelle');
      fetchStructure();
    }
  }, [structure, user, setStructure]);

  const renderTabContent = () => {
    switch (activeTab) {
      case 0:
        return renderInfosStructure();
      case 1:
        return <ImportTab />;
      case 2:
        return <DonneesTab />; // Utilisation du nouveau composant
      default:
        return null;
    }
  };

  const renderInfosStructure = () => {
    if (loading) return <div>Chargement...</div>;
    const currentStructure = structure || localStructure;
    if (!currentStructure) return <div>Structure non disponible</div>;

    return (
      <div className="infos-structure">
        {currentStructure.logo && (
          <img src={currentStructure.logo} alt="Logo de la structure" className="structure-logo" />
        )}
        <p><strong>Nom :</strong> {currentStructure.nom_structure}</p>
        <p><strong>Code :</strong> {currentStructure.code_structure}</p>
        <p><strong>Adresse :</strong> {currentStructure.adresse || 'Non renseigné'}</p>
        <p><strong>Type :</strong> {currentStructure.type_structure || 'Non renseigné'}</p>
        {currentStructure.createdat && (
          <p><strong>Créé le :</strong> {new Date(currentStructure.createdat).toLocaleDateString()}</p>
        )}
        <button className="btn-modifier" onClick={() => setShowEdit(true)}>Modifier</button>
      </div>
    );
  };

  const handleEditClose = () => {
    setShowEdit(false);
    setLocalStructure(null);
    setStructure(null);
    window.location.reload();
  };

  return (
    <div className="une-structure-container">
      <Header />
      <div className="tabs">
        <button 
          className={`tab ${activeTab === 0 ? 'active' : ''}`} 
          onClick={() => setActiveTab(0)}
        >
          Infos
        </button>
        <button 
          className={`tab ${activeTab === 1 ? 'active' : ''}`} 
          onClick={() => setActiveTab(1)}
        >
          Charger
        </button>
        <button 
          className={`tab ${activeTab === 2 ? 'active' : ''}`} 
          onClick={() => setActiveTab(2)}
        >
          Données
        </button>
      </div>
      <div className="tab-content">
        {renderTabContent()}
      </div>

      {showEdit && (
        <Modal onClose={handleEditClose}>
          <AddEditStructure
            mode="edit"
            structure={structure || localStructure}
            onClose={handleEditClose}
          />
        </Modal>
      )}
    </div>
  );
}

export default UneStructure;