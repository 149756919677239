import React, { useState, useContext } from 'react';
import { getReponseApi } from './apis/api_backend';
import { useNavigate } from 'react-router-dom';
import './styles/Login.css';
import { PayEcoleContext } from '../contexts/PayEcoleContext';
import { AuthContext } from '../contexts/AuthContext';
import Header from './Header';
import { User } from 'lucide-react';

const Login = () => {
  const [loginAgent, setLoginAgent] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  
  // Récupération des fonctions du contexte
  const { 
    setUser, 
    setConnectedParent, 
    setIsAdmin, 
    setStructure 
  } = useContext(PayEcoleContext);
  
  const { login } = useContext(AuthContext);

  // Fonction pour afficher l'utilisateur
  const afficherUser = async (user) => {
    try {
      const isAdminGroup = [-1, 0].includes(user.id_groupe);
      setIsAdmin(isAdminGroup);
      
      if (!isAdminGroup) {
        await loadStructure(user.id_structure);
      }
      
      // Redirection après le traitement
      redirectUser(user.profil);
    } catch (error) {
      console.error("Erreur lors du traitement de l'utilisateur:", error);
      setErrorMessage("Erreur lors du chargement des données utilisateur");
    }
  };

  // Fonction pour rediriger l'utilisateur selon son profil
  const redirectUser = (profil) => {
    const routes = {
      SCOLAIRE: '/unestructure',
      COMMERCE: '/unestructure',
      CLIENT: '/homeclient',
      ADMIN: '/systeme'
    };
    
    const route = routes[profil] || '/nobody';
    navigate(route);
  };

  // Fonction pour charger les informations de la structure
  const loadStructure = async (pid_structure) => {
    try {
      const response = await getReponseApi(`/structures/${pid_structure}`, 'GET');
      if (response && response[0]) {
        const params = response[0];

        switch (params.type_structure) {
          case 'SCOLAIRE':
          case 'COMMERCIALE':
          case 'FORMATION PRO':
          case 'PRESTATAIRE DE SERVICES':
            setStructure(params);
            break;
          case 'GENERAL':
            setConnectedParent(params);
            break;
          default:
            break;
        }
      }
    } catch (error) {
      console.error("Erreur lors du chargement des informations de la structure:", error);
      throw new Error("Impossible de charger les informations de la structure");
    }
  };

  // Fonction de gestion de la connexion
  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrorMessage('');

    try {
      const response = await getReponseApi('/utilisateurs/login', 'POST', {
        login: loginAgent,
        pwd: password
      });

      const { token, user } = response;

      if (user && token) {
        localStorage.setItem('token', token);
        login(user);
        setUser(user);
        await afficherUser(user);
      } else {
        setErrorMessage("Identifiants incorrects. Veuillez réessayer.");
      }
    } catch (error) {
      console.error("Erreur de connexion:", error);
      setErrorMessage("Impossible de se connecter. Veuillez vérifier votre connexion.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="login-page">
      <Header />
      <div className="login-background">
        <div className="login-container">
          <div className="login-card">
            <div className="login-card-image-container">
              <img
                className="login-card-image"
                src={`${process.env.PUBLIC_URL}/images/user-icon.png`}
                alt="User Icon"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = <User size={50} />;
                }}
              />
            </div>
            <div className="login-card-body">
              <h2>Bienvenue</h2>
              {errorMessage && (
                <div className="text-danger">
                  {errorMessage}
                </div>
              )}
              <form onSubmit={handleLogin}>
                <div className="loginform-group">
                  <label className="loginlabel">Nom d'utilisateur</label>
                  <input
                    type="text"
                    className="logininput"
                    placeholder="Entrez votre identifiant"
                    value={loginAgent}
                    onChange={(e) => setLoginAgent(e.target.value)}
                    disabled={isLoading}
                  />
                </div>
                <div className="loginform-group">
                  <label className="loginlabel">Mot de passe</label>
                  <input
                    type="password"
                    className="logininput"
                    placeholder="Entrez votre mot de passe"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    disabled={isLoading}
                  />
                </div>
                <button
                  type="submit"
                  className="loginrounded-button"
                  disabled={isLoading}
                >
                  {isLoading ? 'Connexion...' : 'Se connecter'}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;