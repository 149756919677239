import React, { useState, useContext } from 'react';
import { getReponseApi } from '../apis/api_upload';
import { PayEcoleContext } from '../../contexts/PayEcoleContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel, faCloudUploadAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import '../styles/ImportTab.css';

const ImportTab = () => {
  const [file, setFile] = useState(null);
  const [factures, setFactures] = useState(0);
  const [message, setMessage] = useState({ text: '', type: '' });
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useContext(PayEcoleContext);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile.type.includes('excel') || selectedFile.name.match(/\.(xls|xlsx)$/)) {
        setFile(selectedFile);
        setMessage({ text: '', type: '' });
      } else {
        setMessage({
          text: 'Veuillez sélectionner un fichier Excel valide (.xls, .xlsx)',
          type: 'error'
        });
      }
    }
  };

  const handleFacturesChange = (e) => {
    const value = Math.max(0, parseInt(e.target.value) || 0);
    setFactures(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!file) {
      setMessage({ text: 'Veuillez sélectionner un fichier.', type: 'error' });
      return;
    }

    if (!user) {
      setMessage({ text: 'Utilisateur non connecté.', type: 'error' });
      return;
    }

    setIsLoading(true);
    const formData = new FormData();
    formData.append('file', file);
    formData.append('user_id', user.id);
    formData.append('id_structure', user.id_structure);
    formData.append('factures', factures);

    try {
      const response = await getReponseApi('/getfile/uploadfilexls', 'POST', formData);

      if (response && response.message) {
        setMessage({
          text: `Import réussi - ${response.details.lignesImportees} lignes importées, ${response.details.facturesGenerees} factures générées.`,
          type: 'success'
        });
        setFile(null);
        setFactures(0);
      } else {
        throw new Error('Réponse invalide du serveur');
      }
    } catch (error) {
      setMessage({
        text: 'Une erreur est survenue lors de l\'importation.',
        type: 'error'
      });
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const clearFile = () => {
    setFile(null);
    setMessage({ text: '', type: '' });
  };

  return (
    <div className="import-container">
      <h2 className="import-title">
        <FontAwesomeIcon icon={faCloudUploadAlt} className="mr-2" />
        Importation de données
      </h2>

      <form onSubmit={handleSubmit}>
        <div className="file-upload-container">
          <input
            id="fileInput"
            type="file"
            accept=".xls,.xlsx"
            onChange={handleFileChange}
            className="file-input"
          />
          <label htmlFor="fileInput" className="file-upload-label">
            <FontAwesomeIcon icon={faFileExcel} className="file-icon" />
            <span className="text-lg font-medium">
              {file ? 'Fichier sélectionné' : 'Glissez votre fichier Excel ici ou cliquez pour parcourir'}
            </span>
            {file && (
              <div className="file-name">
                {file.name}
                <button
                  type="button"
                  onClick={clearFile}
                  className="ml-2 text-red-500 hover:text-red-700"
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
            )}
          </label>
        </div>

        <div className="factures-input-container">
          <label htmlFor="facturesInput" className="block text-sm font-medium text-gray-700 mb-1">
            Nombre de factures à générer
          </label>
          <input
            id="facturesInput"
            type="number"
            value={factures}
            onChange={handleFacturesChange}
            className="factures-input"
            min="0"
            placeholder="Entrez le nombre de factures..."
          />
        </div>

        <button
          type="submit"
          disabled={isLoading || !file}
          className="submit-button"
        >
          {isLoading ? (
            <>
              <span className="loading-spinner" />
              Importation en cours...
            </>
          ) : (
            'Importer les données'
          )}
        </button>
      </form>

      {message.text && (
        <div className={`message ${message.type}`}>
          {message.text}
        </div>
      )}
    </div>
  );
};

export default ImportTab;