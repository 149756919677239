import React, { useState, useContext } from 'react';
import { getReponseApi } from '../apis/api_upload';
import { PayEcoleContext } from '../../contexts/PayEcoleContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faSave, 
  faEraser, 
  faSpinner,
  faShoppingCart
} from '@fortawesome/free-solid-svg-icons';
import '../styles/ImportTab.css'; // Réutilisation du même style

const DonneesTab = () => {
  const { user } = useContext(PayEcoleContext);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState({ text: '', type: '' });

  const [formData, setFormData] = useState({
    nom_produit: '',
    nom_client: '',
    tel_client: '',
    montant: '',
    factures: 1
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'montant' || name === 'factures') {
      // Pour les champs numériques, on s'assure d'avoir une valeur positive
      const numValue = Math.max(0, Number(value));
      setFormData(prev => ({
        ...prev,
        [name]: numValue
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const resetForm = () => {
    setFormData({
      nom_produit: '',
      nom_client: '',
      tel_client: '',
      montant: '',
      factures: 1
    });
    setMessage({ text: '', type: '' });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setMessage({ text: '', type: '' });

    try {
      if (!formData.nom_produit || !formData.montant) {
        throw new Error('Le nom du produit et le montant sont obligatoires');
      }

      const response = await getReponseApi('/getfile/create_one', 'POST', {
        ...formData,
        id_structure: user.id_structure
      });

      if (response && response.message) {
        setMessage({
          text: `Enregistrement réussi - ${response.details.facturesGenerees} facture(s) générée(s)`,
          type: 'success'
        });
        resetForm();
      }
    } catch (error) {
      setMessage({
        text: error.message || 'Une erreur est survenue lors de la création',
        type: 'error'
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="import-container">
        <h2 className="import-title">
            <FontAwesomeIcon icon={faShoppingCart} />
            Ajout d'un produit/service
        </h2>

        <form onSubmit={handleSubmit}>
            <div className="form-grid">
                {/* Nom du produit */}
                <div className="form-field">
                    <label className="form-label">
                        Nom du produit/service *
                    </label>
                    <input
                        type="text"
                        name="nom_produit"
                        value={formData.nom_produit}
                        onChange={handleChange}
                        className="form-input"
                        placeholder="Ex: Thiebou dien"
                        required
                    />
                </div>

                {/* Montant */}
                <div className="form-field">
                    <label className="form-label">
                        Montant *
                    </label>
                    <input
                        type="number"
                        name="montant"
                        value={formData.montant}
                        onChange={handleChange}
                        className="form-input"
                        placeholder="Ex: 5000"
                        min="0"
                        required
                    />
                </div>

                {/* Nom du client */}
                <div className="form-field">
                    <label className="form-label">
                        Nom du client
                    </label>
                    <input
                        type="text"
                        name="nom_client"
                        value={formData.nom_client}
                        onChange={handleChange}
                        className="form-input"
                        placeholder="Ex: John Doe"
                    />
                </div>

                {/* Téléphone du client */}
                <div className="form-field">
                    <label className="form-label">
                        Téléphone client
                    </label>
                    <input
                        type="text"
                        name="tel_client"
                        value={formData.tel_client}
                        onChange={handleChange}
                        className="form-input"
                        placeholder="Ex: 771234567"
                        maxLength="9"
                    />
                </div>

                {/* Nombre de factures - pleine largeur */}
                <div className="form-field full-width">
                    <label className="form-label">
                        Nombre de factures
                    </label>
                    <input
                        type="number"
                        name="factures"
                        value={formData.factures}
                        onChange={handleChange}
                        className="form-input"
                        min="1"
                        placeholder="Ex: 1"
                    />
                </div>
            </div>

            <div className="button-container">
                <button
                    type="submit"
                    disabled={isLoading}
                    className="submit-button"
                >
                    {isLoading ? (
                        <>
                            <FontAwesomeIcon icon={faSpinner} spin />
                            Création...
                        </>
                    ) : (
                        <>
                            <FontAwesomeIcon icon={faSave} />
                            Créer
                        </>
                    )}
                </button>

                <button
                    type="button"
                    onClick={resetForm}
                    className="cancel-button"
                    disabled={isLoading}
                >
                    <FontAwesomeIcon icon={faEraser} />
                    Effacer
                </button>
            </div>
        </form>

        {message.text && (
            <div className={`message ${message.type}`}>
                {message.text}
            </div>
        )}
    </div>
);
}

export default DonneesTab;