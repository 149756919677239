import React, { useState, useEffect, useCallback,useRef } from 'react';
import { getReponseApi } from '../apis/api_backend';

const CircularProgress = ({ percentage }) => {
  const circumference = 2 * Math.PI * 45;
  const strokeDashoffset = circumference - (percentage / 100) * circumference;

  return (
    <div className="relative w-32 h-32">
      <svg className="transform -rotate-90 w-32 h-32">
        <circle
          cx="64"
          cy="64"
          r="45"
          stroke="#e6e6e6"
          strokeWidth="8"
          fill="none"
        />
        <circle
          cx="64"
          cy="64"
          r="45"
          stroke="#ff6b00"
          strokeWidth="8"
          fill="none"
          strokeLinecap="round"
          style={{
            strokeDasharray: circumference,
            strokeDashoffset,
            transition: 'stroke-dashoffset 0.5s ease'
          }}
        />
      </svg>
      <div className="absolute inset-0 flex items-center justify-center">
        <span className="text-xl font-bold text-gray-700">
          {Math.round(percentage)}%
        </span>
      </div>
    </div>
  );
};

const ProcessingModal = ({ isOpen, onComplete, id_facture, onStatusChange }) => {
  const [progress, setProgress] = useState(0);
  const [paymentConfirmed, setPaymentConfirmed] = useState(false);
  const statusCheckRef = useRef(null);
  const progressIntervalRef = useRef(null);
  const apiCallCountRef = useRef(0);
  const checkInterval = 15000;
  const MAX_API_CALLS = 5;

  // Logger personnalisé pour un meilleur suivi
  const logApiCall = useCallback((message, type = 'info') => {
    const timestamp = new Date().toLocaleTimeString();
    const prefix = `[${timestamp}] API Call ${apiCallCountRef.current}/${MAX_API_CALLS}:`;
    
    switch(type) {
      case 'success':
        console.log(`%c${prefix} ${message}`, 'color: green; font-weight: bold');
        break;
      case 'error':
        console.error(`${prefix} ${message}`);
        break;
      case 'warning':
        console.warn(`${prefix} ${message}`);
        break;
      default:
        console.log(`${prefix} ${message}`);
    }
  }, [MAX_API_CALLS]);

  // Fonction pour terminer rapidement le processus
  const completeProgress = useCallback(() => {
    // Accélérer la progression jusqu'à 100%
    const quickComplete = setInterval(() => {
      setProgress(prev => {
        if (prev >= 100) {
          clearInterval(quickComplete);
          setTimeout(onComplete, 500); // Réduit à 500ms au lieu de 1000ms
          return 100;
        }
        return prev + 5; // Progression plus rapide
      });
    }, 20); // Intervalle plus court pour une animation plus rapide

    return () => clearInterval(quickComplete);
  }, [onComplete]);

  // Fonction pour terminer le processus
  const finishProcess = useCallback((success = false) => {
    // Nettoyer les intervalles
    if (statusCheckRef.current) {
      clearInterval(statusCheckRef.current);
      statusCheckRef.current = null;
    }
    if (progressIntervalRef.current) {
      clearInterval(progressIntervalRef.current);
      progressIntervalRef.current = null;
    }

    if (success) {
      logApiCall("Paiement confirmé ! Fermeture du processus...", 'success');
      setPaymentConfirmed(true);
      onStatusChange(true);
      completeProgress();
    } else {
      logApiCall("Limite d'appels atteinte. Fermeture du processus.", 'warning');
      completeProgress();
    }
  }, [onStatusChange, completeProgress, logApiCall]);

  // Fonction de vérification de statut
  const checkFactureStatus = useCallback(async () => {
    try {
      apiCallCountRef.current += 1;
      logApiCall("Vérification de l'état de la facture...");

      const response = await getReponseApi(`/factures/unefacture/${id_facture}`, 'GET');
      
      if (apiCallCountRef.current >= MAX_API_CALLS) {
        logApiCall("Limite d'appels API atteinte", 'warning');
        finishProcess(false);
        return true;
      }

      if (response && response.id_etat === 2) {
        logApiCall("Paiement confirmé par l'API", 'success');
        finishProcess(true);
        return true;
      } else {
        logApiCall(`État actuel: ${response?.id_etat || 'inconnu'}`);
      }

      return false;
    } catch (error) {
      logApiCall(`Erreur: ${error.message}`, 'error');
      if (apiCallCountRef.current >= MAX_API_CALLS) {
        finishProcess(false);
      }
      return false;
    }
  }, [id_facture, finishProcess, logApiCall]);

  // Effet pour la vérification périodique
  useEffect(() => {
    if (isOpen && !paymentConfirmed) {
      logApiCall("Démarrage des vérifications périodiques");
      checkFactureStatus();

      statusCheckRef.current = setInterval(checkFactureStatus, checkInterval);

      return () => {
        if (statusCheckRef.current) {
          clearInterval(statusCheckRef.current);
          statusCheckRef.current = null;
        }
      };
    }
  }, [isOpen, paymentConfirmed, checkFactureStatus, checkInterval, logApiCall]);

  // Effet pour la progression
  useEffect(() => {
    if (!isOpen) return;

    if (!paymentConfirmed && progress < 95) {
      progressIntervalRef.current = setInterval(() => {
        setProgress(prev => {
          const newProgress = prev + (95 / (checkInterval * 0.1));
          if (newProgress >= 95) {
            clearInterval(progressIntervalRef.current);
            progressIntervalRef.current = null;
            return 95;
          }
          return newProgress;
        });
      }, 100);
    }

    return () => {
      if (progressIntervalRef.current) {
        clearInterval(progressIntervalRef.current);
        progressIntervalRef.current = null;
      }
    };
  }, [isOpen, progress, paymentConfirmed, checkInterval]);

  // Message personnalisé en fonction de l'état
  const getMessage = () => {
    if (apiCallCountRef.current >= MAX_API_CALLS) {
      return "Vérification terminée. Consultez l'état de votre paiement.";
    }
    if (paymentConfirmed) {
      return "Transaction terminée avec succès";
    }
    if (progress >= 95) {
      return "Finalisation...";
    }
    return [
      "Initialisation de la transaction...",
      "Vérification des informations...",
      "Traitement du paiement...",
    ][Math.floor((progress / 95) * 12)] || "Initialisation...";
  };

  if (!isOpen) return null;

  return (
    <div className="confirmation-overlay">
      <div className="confirmation-dialog" style={{ maxWidth: '400px' }}>
        <div className="text-center p-6">
          <div className="flex justify-center mb-6">
            <CircularProgress percentage={progress} />
          </div>
          
          <h2 className="text-xl font-semibold text-gray-800 mb-3">
            Traitement en cours
          </h2>
          
          <p className="text-gray-600 mb-4">
            {getMessage()}
          </p>

          <p className="text-blue-600 font-bold mt-4">
            Veuillez vérifier votre téléphone pour le SMS de paiement
          </p>

          {progress === 100 && (
            <div className="mt-4 text-green-600 flex flex-col items-center">
              <svg className="w-12 h-12" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                <path 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  strokeWidth={2} 
                  d="M5 13l4 4L19 7"
                />
              </svg>
              <p className="mt-2 font-medium">
                {paymentConfirmed 
                  ? "Paiement traité avec succès!"
                  : "Vérification terminée"}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProcessingModal;