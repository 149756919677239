// frontend/src/services/imageService.js
//import { getReponseApi } from '../apis/api_backend';

const IMAGE_UPLOAD_URL = `${process.env.REACT_APP_API_URL}/getfile/upload_image`;
const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
const ALLOWED_TYPES = ['image/jpeg', 'image/png', 'image/gif'];

class ImageService {
  static async uploadImage(file, onProgress = () => {}) {
    if (!file) throw new Error('Aucun fichier sélectionné');
    
    // Validation du fichier
    if (!ALLOWED_TYPES.includes(file.type)) {
      throw new Error('Format de fichier non supporté. Utilisez JPG, PNG ou GIF');
    }
    
    if (file.size > MAX_FILE_SIZE) {
      throw new Error(`La taille du fichier ne doit pas dépasser ${MAX_FILE_SIZE / (1024 * 1024)}MB`);
    }

    const formData = new FormData();
    formData.append('logo', file);

    try {
      const response = await fetch(IMAGE_UPLOAD_URL, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: formData
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Erreur lors de l\'upload');
      }

      const data = await response.json();
      return data.imageUrl; // URL de l'image uploadée
    } catch (error) {
      console.error('Erreur upload:', error);
      throw error;
    }
  }

  static getImageUrl(path) {
    if (!path) return null;
    if (path.startsWith('http')) return path;
    return `${process.env.REACT_APP_API_URL}/uploads/${path}`;
  }
}

export default ImageService;