import React, { useState, useEffect } from 'react';
import { getReponseApi } from '../apis/api_backend';
import { X } from 'lucide-react';
import  '../styles/EditFactureModal.css';

const EditFactureModal = ({ facture, isOpen, onClose, onUpdate, isLoading }) => {
    const [formData, setFormData] = useState({
      nannee: '',
      nmois: '',
      nom_classe: '',
      tel_client: '',
      nom_client_payeur: '',
      montant: '',
      id_etat: ''
    });
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (facture) {
          setFormData({
            nannee: facture.nannee || '',
            nmois: facture.nmois || '',
            nom_classe: facture.nom_classe || '',
            tel_client: facture.tel_client || '',
            nom_client_payeur: facture.nom_client || '', // Correction ici
            montant: facture.montant || '',
            id_etat: facture.id_etat || ''
          });
        }
      }, [facture]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };
    // Préparer les données avec les bons types
      
   const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      // Appel API pour mettre à jour la facture
      const response = await getReponseApi(
        `/factures/updateFacture/${facture.id_facture}`,
        'PUT',
        formData
      );

    if (response && response.id_facture) {
        // Appeler la fonction onUpdate du parent avec les données mises à jour
       
        await onUpdate(facture.id_facture, response);
        onClose();
    } else {
        throw new Error('La mise à jour a échoué');
    }
    } catch (err) {
    setError(err.message || 'Une erreur est survenue lors de la mise à jour');
    console.error('Erreur lors de la mise à jour:', err);
    } finally {
    setLoading(false);
    }
    };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header">
          <h2>Modifier la facture</h2>
          <button onClick={onClose} className="close-button">
            <X size={24} />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="modal-form">
          {error && <div className="error-message">{error}</div>}
          
          {/* Première rangée : Année et Mois */}
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="nannee">Année</label>
              <input
                type="number"
                id="nannee"
                name="nannee"
                value={formData.nannee}
                onChange={handleChange}
                min="2000"
                max="2100"
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="nmois">Mois</label>
              <select
                id="nmois"
                name="nmois"
                value={formData.nmois}
                onChange={handleChange}
                required
              >
                <option value="">Sélectionner un mois</option>
                {[...Array(12)].map((_, i) => (
                  <option key={i + 1} value={i + 1}>
                    {new Date(0, i).toLocaleString('fr-FR', { month: 'long' })}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/* Deuxième rangée : Article et Montant */}
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="nom_classe">Article</label>
              <input
                type="text"
                id="nom_classe"
                name="nom_classe"
                value={formData.nom_classe}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="montant">Montant</label>
              <input
                type="number"
                id="montant"
                name="montant"
                value={formData.montant}
                onChange={handleChange}
                min="0"
                step="0.01"
                required
              />
            </div>
          </div>

          {/* Les autres champs restent en pleine largeur */}
          <div className="form-group">
            <label htmlFor="tel_client">Téléphone client</label>
            <input
              type="text"
              id="tel_client"
              name="tel_client"
              value={formData.tel_client}
              onChange={handleChange}
              pattern="\d{9}"
              title="Le numéro doit contenir exactement 9 chiffres"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="nom_client_payeur">Nom du client</label>
            <input
              type="text"
              id="nom_client_payeur"
              name="nom_client_payeur"
              value={formData.nom_client_payeur}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="id_etat">État</label>
            <select
              id="id_etat"
              name="id_etat"
              value={formData.id_etat}
              onChange={handleChange}
              required
            >
              <option value="1">En attente</option>
              <option value="2">Payée</option>
              <option value="3">Annulée</option>
            </select>
          </div>

          <div className="modal-actions">
            <button 
              type="button" 
              onClick={onClose}
              className="cancel-button"
              
              disabled={loading}
            >
              Annuler
            </button>
            <button 
              type="submit" 
              className="submit-button"
              disabled={loading}
            >
              {isLoading ? 'Mise à jour...' : 'Enregistrer'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditFactureModal;