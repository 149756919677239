// frontend/src/components/apis/api_backend.js

// Fonction pour récupérer le token JWT (à adapter selon la manière dont vous stockez le token, ex: localStorage)
function getToken() {
    return localStorage.getItem('token'); // Suppose que le token est stocké dans localStorage
}

export async function getReponseApi(endpoint, method = 'GET', bodyParams = null, additionalHeaders = {}) {
    const baseUrl = process.env.REACT_APP_API_URL; // URL de votre backend, à adapter selon votre environnement
    const url = `${baseUrl}${endpoint}`; // Construire l'URL complète avec l'endpoint

    // Récupérer le token JWT
    const token = getToken();

    // Préparation des headers avec le token JWT
    const headers = {
        ...(token && { 'Authorization': `Bearer ${token}` }),
        ...additionalHeaders
    };

    const options = {
        method,
        headers,
    };

    if (!(bodyParams instanceof FormData)) {
        // Ajout du body en JSON si bodyParams n'est pas un FormData
        options.body = JSON.stringify(bodyParams);
        headers['Content-Type'] = 'application/json';
    } else {
        options.body = bodyParams; // FormData ne doit pas avoir de Content-Type
    }

    try {
        const response = await fetch(url, options);

        // Vérification de la réponse
        if (!response.ok) {
            throw new Error(`Network response was not ok, status: ${response.status}`);
        }

        const data = await response.json(); // Conversion en objet JSON

        // Vérification si data est un tableau non vide ou un objet
        if (Array.isArray(data) && data.length > 0) {
            return data;
        } else if (data && typeof data === 'object' && Object.keys(data).length > 0) {
            return data;
        } else {
            throw new Error('Données retournées invalides ou vides');
        }
    } catch (error) {
        console.error('Erreur lors de la requête API:', error);
        return null; // Retourne null en cas d'erreur
    }
}